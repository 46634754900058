import * as React from "react"
import { Link } from "gatsby"
import Navbar from "../navbar"

import "./style.css"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = (location.pathname === rootPath + "notes/") || (location.pathname === rootPath + "notes") 

  let header

  if (isRootPath) {
    header = (
      <h1 className="page-title" id="blog-page-title">
        <span style={{ color: "var(--pink)" }}>N</span>
        <span style={{ color: "var(--yellow)" }}>O</span>
        <span style={{ color: "var(--green)" }}>T</span>
        <span style={{ color: "var(--darkblue)" }}>E</span>
        <span style={{ color: "var(--purple)" }}>S</span>
      </h1>
    )
  } else {
    header = (
      <p>
        <Link to="/notes/" style={{color: "#545454"}}>&larr; back</Link>
      </p>
    )
  }

  return (
    <div className="blog-page page center-container">
      <div
        // className="global-wrapper"
        data-is-root-path={isRootPath}
      >
        <Navbar />
        {header}
        <main>{children}</main>
        {/* <footer>© {new Date().getFullYear()}</footer> */}
      </div>
    </div>
  )
}

export default Layout
